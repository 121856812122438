import type { LocationQueryValue } from '#vue-router';

export const useEcommerce = () => {
    const { t } = useLocales();
    const cartStore = useCartStore();
    const { isBike } = useProduct();

    class ProductOption implements IProductOption {
        #selected: Ref<string> = ref('');
        #currentVariants: Ref<CMSProductVariant[]> = ref([]);
        #sortedValuesWithAvailibility: OptionValue[] = [];
        constructor(
            private parent: ConfigurableProduct,
            public type: string,
        ) {
            // pick the first option available
            if (this.parent.raw.options && Array.isArray(this.parent.raw.options[this.type]) && this.parent.raw.options[this.type].length > 0) {
                this.#selected.value = this.parent.raw.options[this.type][0];
            } else {
                this.#selected.value = '';
            }
        }

        /**
         * call after all options are set on parent
         */
        initOtherOptions() {
            if (!this.canRender) return;

            const otherSelectedOptionValues = this.parent.options
                .filter((option) => {
                    return option.type !== this.type && option.canRender;
                })
                .map((option) => option.selectedRef);

            watch(
                () => otherSelectedOptionValues,
                () => {
                    const variantKeys = Object.keys(this.parent.variantLookup).filter((key) =>
                        otherSelectedOptionValues.every(
                            (value) =>
                                key.toLowerCase().includes(`-${value.value.toLowerCase()}`) ||
                                key.toLowerCase().includes(`${value.value.toLowerCase()}-`),
                        ),
                    );

                    const variantIndexes = variantKeys.map((key) => this.parent.variantLookup[key]);
                    this.#currentVariants.value = this.parent.raw.variants.items.filter((_, index) => variantIndexes.includes(index));
                },
                { deep: true, immediate: true },
            );

            this.#sortedValuesWithAvailibility = this.stringValues.map((value) => {
                const disabled = ref(false);
                watch(
                    () => this.#currentVariants.value,
                    () => {
                        const variantWithOption = this.#currentVariants.value.find((av) => av.selectedOptions[this.type] === value);
                        disabled.value = variantWithOption?.availabilityState !== ProductAvailability.AVAILABLE;
                    },
                    { immediate: true },
                );
                return {
                    value,
                    isSelected: computed(() => value === this.#selected.value),
                    disabled,
                };
            });
        }

        get selectedRef() {
            return this.#selected;
        }

        get values() {
            return this.#sortedValuesWithAvailibility;
        }

        get stringValues(): string[] {
            return this.parent.raw.options?.[this.type] ?? [];
        }

        /**
         * if we want a control to be rendered or not
         * we exclude on Bikes: Edition and Revision if they have one value
         */
        get canRender(): boolean {
            return !(this.stringValues.length < 2 && hiddenProductAttributes.includes(this.type));
        }

        setSelected(value: string, findMatchingVariant = true) {
            // find correct option with lowercase
            const _value = this.parent.raw.options?.[this.type]?.find((option) => option.toLowerCase() === value.toLowerCase());
            this.#selected.value = _value ?? '';
            if (findMatchingVariant) this.parent.findMatchingVariant();
        }
    }

    // add one or multiple products, and manage the selection state of each products options
    class ConfigurableProduct implements IConfigurable {
        selected: Ref<CMSProductVariant | undefined>;
        options: IProductOption[] = [];
        optionsRendarable: string[] = [];
        variantLookup: Record<string, number> = {};
        attributes: Record<string, any> = {};
        valid: Ref<boolean>;
        validationError: Ref<string | undefined>;
        nameError: Ref<string | undefined>;
        originalSelectionError: Ref<string | undefined>;

        constructor(
            public raw: CMSProduct,
            private initOptions: {
                preselectSku?: LocationQueryValue | LocationQueryValue[];
                variantUrl?: LocationQueryValue | LocationQueryValue[];
            } = {},
        ) {
            this.selected = ref();
            this.valid = ref(false);
            this.validationError = ref();
            this.nameError = ref();
            this.originalSelectionError = ref();

            for (let i = 0; i < raw.variants.items.length; i += 1) {
                const keyParts: string[] = [];
                const variant = raw.variants.items[i];
                for (const vk of raw.optionsSequence) {
                    keyParts.push(variant.selectedOptions[vk]);
                }
                this.variantLookup[this.#keyPartsToKey(keyParts)] = i;
            }
            for (const o of this.raw.optionsSequence) {
                const productOption = new ProductOption(this, o);
                this.options.push(productOption);
                if (productOption.canRender) this.optionsRendarable.push(o);
            }
            this.options.forEach((option) => {
                option.initOtherOptions();
            });
            this.#preSelectVariant();
            this.#checkInitialValue(this.selected.value);

            this.validate();
            // check if we have a limit and need to validate the cart store
            if (this.raw.limit > 0) {
                const { cartItems } = storeToRefs(cartStore);
                watch(cartItems, () => {
                    // revalidate
                    this.validate();
                });
            }
        }

        #keyPartsToKey(keyParts: string[]) {
            return keyParts.join('-').toLowerCase();
        }

        #preSelectVariant() {
            const variants = this.raw.variants.items;

            if (variants.length === 1) {
                this.selected.value = variants[0];
                return;
            }

            const sku = unArray(this.initOptions.preselectSku);
            if (sku) {
                const variant = variants.find((variant) => variant.sku === sku);
                if (variant) {
                    this.selected.value = variant;
                    return;
                }
            }

            const variantUrl = unArray(this.initOptions.variantUrl);
            if (variantUrl) {
                let splitVariant = variantUrl.toLowerCase().split('_');
                if (this.raw.options && splitVariant.length) {
                    let optionSelected = false;
                    const trySetOption = (variant: string, stringValues: string[], option: IProductOption) => {
                        const isAvailable = stringValues.includes(variant);
                        if (isAvailable) option.setSelected(variant);
                        optionSelected = optionSelected || isAvailable;
                        return isAvailable;
                    };
                    for (const key in this.raw.options ?? {}) {
                        const option = this.options.find(({ type }) => type === key);
                        if (!option) continue;

                        const stringValues = option.stringValues.map((string) => string.toLowerCase());
                        splitVariant = splitVariant.filter((variant) => {
                            // if option is set, filter it out
                            if (trySetOption(variant, stringValues, option)) return false;
                            // check for alternative writing (e.g. 'woom-red' === 'Woom Red')
                            const alternativeVariant = variant.replace('-', ' ');
                            return !trySetOption(alternativeVariant, stringValues, option);
                        });
                    }
                    if (optionSelected) return;
                }
            }

            this.selected.value = this.raw.tags.includes('disable_variant_stock_redirect')
                ? variants[0]
                : variants.find((variant) => variant.availabilityState === ProductAvailability.AVAILABLE) || variants[0];
        }

        #checkInitialValue(initialValue: CMSProductVariant | undefined) {
            if (initialValue) {
                const opKeys = Object.keys(initialValue.selectedOptions);
                for (const opK of opKeys) {
                    const o = this.options.find((p: IProductOption) => p.type === opK);
                    if (o) {
                        o.setSelected(initialValue.selectedOptions[opK], false);
                    } else {
                        console.log('color option not found for', opK);
                    }
                }
            }
        }

        /**
         * check if we have all additional props valid
         */
        validate(setError: boolean = true) {
            // check if we are on a limit
            if (this.raw.limit > 0) {
                const limitReached = cartStore.isLimitReached(this.raw.id, this.raw.limit);
                if (limitReached) {
                    this.valid.value = false;
                    this.validationError.value = 'cart.limit';
                    return false;
                }
            }
            if (this.raw.customNaming) {
                if (!(this.attributes.Name && this.attributes.Name.trim().length > 0)) {
                    this.valid.value = false;
                    if (setError) {
                        this.nameError.value = 'forms.validation.required';
                    }
                    return false;
                }
            }
            if (this.raw.tags.includes('pdp-original-selection')) {
                const key = t('bikeOptions.original.key');
                if (!(this.attributes[key] && this.attributes[key].trim().length > 0)) {
                    this.valid.value = false;
                    if (setError) {
                        this.originalSelectionError.value = 'forms.validation.required';
                    }
                    return false;
                }
            }

            // WILL BE ENABLED IN ECOMM-833
            // if (this.isGiftCard && !this.attributes[giftcardFormValidKey]) {
            //     this.validationError.value = 'giftcard.formInvalid';
            //     this.valid.value = false;
            //     return false;
            // }

            this.valid.value = true;
            this.validationError.value = undefined;
            this.nameError.value = undefined;
            this.originalSelectionError.value = undefined;
            return true;
        }

        selectedStatus(): ProductAvailabilityType | undefined {
            return this.selected.value?.availabilityState;
        }

        /**
         * based on the current product options, find the matching variant in the list
         */
        findMatchingVariant() {
            // build variant key
            const keyParts: string[] = [];
            for (const k of this.raw.optionsSequence) {
                const option = this.options.find((p) => p.type.includes(k));
                if (option) {
                    keyParts.push(option.selectedRef.value);
                }
            }
            const key = this.#keyPartsToKey(keyParts);

            if (typeof this.variantLookup[key] === 'number') {
                this.selected.value = this.raw.variants.items[this.variantLookup[key]];
            } else {
                this.selected.value = undefined;
                console.error('key not found in variant lookup', key);
            }
        }

        get selectedId() {
            return this.selected.value?.id;
        }

        get productId() {
            return this.raw.id;
        }

        get hasValidCurrent(): boolean {
            if (!this.selected.value) return false;
            // do validation here
            return true;
        }

        /**
         * if we have any option values we need to render
         */
        get hasRenderOptions(): boolean {
            for (const op of this.options) {
                if (op.canRender) {
                    return true;
                }
            }
            if (this.raw.customNaming) {
                return true;
            }
            if (this.raw.tags.includes('pdp-original-selection')) {
                return true;
            }
            return false;
        }

        get isBike() {
            return Boolean(isBike(this.raw));
        }
        get isDigital() {
            return this.raw.tags.includes('autofulfill');
        }

        /**
         * if the product requires a name field
         */
        get hasNameField() {
            return this.raw.customNaming;
        }

        get isGiftCard() {
            return this.raw.tags.includes('gift-card-postman-product');
        }

        setCustomField(key: string, value: string | string[] | undefined | null) {
            if (!value) {
                delete this.attributes[key];
            } else {
                const _value = Array.isArray(value) ? value.join(',') : value;
                this.attributes[key] = _value;
            }
            this.validate();
        }

        /**
         * if the product requires a multi dropdown for original bikes
         */
        get hasOriginalSelection() {
            return this.raw.tags.includes('pdp-original-selection');
        }

        /**
         * return the current config selection to be added to cart
         */
        get current(): AddToCartItem | undefined {
            if (this.selected.value) {
                // console.log('add to cart item', this.raw);
                const runTimeAttributes: any = {};
                if (this.raw.orderCode && this.raw.orderCode !== '') {
                    runTimeAttributes._bundle_code = this.raw.orderCode;
                }
                if (this.selected.value.availabilityState === ProductAvailability.PREORDER) {
                    runTimeAttributes['Pre-Order'] = this.selected.value.preorderMessage;
                }
                if (this.raw.limit > 0) {
                    runTimeAttributes._limit = `${this.raw.limit}`;
                }
                return {
                    product: this.raw,
                    variant: this.selected.value,
                    qty: 1,
                    attributes: {
                        ...this.attributes,
                        ...runTimeAttributes,
                        _isBike: `${this.isBike}`,
                    },
                };
            }
            return undefined;
        }

        get variantUrlQuery() {
            return computed(() => {
                const optionsForUrl: string[] = [];

                for (const optionKey in this.selected.value?.selectedOptions ?? {}) {
                    // @ts-expect-error
                    if (this.optionsRendarable.includes(optionKey)) optionsForUrl.push(this.selected.value.selectedOptions[optionKey]);
                }
                return optionsForUrl.length
                    ? optionsForUrl
                          .map((option) => option.replace(' ', '-'))
                          .join('_')
                          .toLocaleLowerCase()
                    : null;
            });
        }

        get variantUrl() {
            return computed(() => `${this.raw.url}?variant=${this.variantUrlQuery.value ?? ''}`);
        }
    }
    return {
        ConfigurableProduct,
    };
};
